const settings = {
  settings: "/settings",
  settingsDetails: "/settings/details",
  settingsTeam: "/settings/team",
  settingsEmail: "/settings/email",
  settingsRoles: "/settings/roles",
} as const;

const eInvoicing = {
  einvoicing: "/e-invoicing",
  einvoicingSuppliers: "/e-invoicing/suppliers",
  einvoicingTransactions: "/e-invoicing/transactions",
  einvoicingImportOverview: "/e-invoicing/data-imports",
  einvoicingDocumentationHomePage: "/e-invoicing/documentation",
  einvoicingCountryGuides: "/e-invoicing/documentation/country-guides",
  einvoicingMasterData: "/e-invoicing/documentation/master-data",
  einvoicingErrorDocumentation: "/e-invoicing/documentation/error-documentation",
  einvoicingCountryGuidePage: (id: string) => `/e-invoicing/documentation/country-guides/${id}`,
  eInvoicingBatchTransactions: (batchId: string) =>
    `/e-invoicing/data-imports/${batchId}/batch-transactions`,
  eInvoicingTransactions: (
    dateFrom: Date,
    dateTo: Date,
    errorCode?: string,
    direction?: string,
    countryCode?: string,
    currencyCode?: string,
    transactionStatus?: string,
    transactionScenario?: string
  ) =>
    `/e-invoicing/transactions?transaction_date_from=${dateFrom.toISOString()}&transaction_date_to=${dateTo.toISOString()}` +
    (errorCode
      ? `&error_types=${errorCode.toUpperCase() === "OTHER" ? "GENERAL" : errorCode.toUpperCase()}`
      : "") +
    (direction ? `&direction=${direction}` : "") +
    (countryCode ? `&country_code=${countryCode}` : "") +
    (currencyCode ? `&currency_code=${currencyCode}` : "") +
    (transactionStatus ? `&transaction_status=${transactionStatus}` : "") +
    (transactionScenario ? `&transaction_scenario=${transactionScenario}` : ""),
} as const;

const reporting = {
  reporting: "/reporting",
  reportingCompanies: "/reporting/companies",
  reportingTransactions: "/reporting/transactions",
  reportingTransactionsErrors: (dateFrom: Date, dateTo: Date, errorCode: string) =>
    `/reporting/transactions?date_from=${dateFrom.toISOString()}&date_to=${dateTo.toISOString()}&tab=error-transactions&error_category=${errorCode}`,
} as const;

const invoicing = {
  invoicing: "/invoicing",
  invoicingOverview: "/invoicing/overview",
  invoicingTemplates: "/invoicing/templates",
  invoicingTemplatesNew: "/invoicing/templates/new",
  invoicingTemplatesEdit: (templateId: string | number) => `/invoicing/templates/${templateId}`,
  invoicingTemplatesEditDefault: "/invoicing/templates/default",
} as const;

const tax = {
  tax: "/tax",
  taxUSNexus: "/tax/usa",
  taxGlobalThresholds: "/tax/global",
  taxCalculations: "/tax/calculations",
  taxExternalScenarios: "/tax/external_scenarios",
  taxSimulatorNew: "/tax/simulator/new",
  taxSettings: "/tax/settings",
  taxSettingsRules: "/tax/settings/rules",
  taxSettingsRegistrations: "/tax/settings/registrations",
  taxSettingsRegistrationsImport: "/tax/settings/registrations/import",
  taxSettingsNexus: "/tax/settings/nexus",
  taxSettingsNexusImport: "/tax/settings/nexus/import",
  taxSettingsGlobalImport: "/tax/settings/global/import",
  taxAddressValidations: "/tax/address",
  taxReports: "/tax/reports",
} as const;

const lookup = {
  lookup: "/lookup",
  lookupInfoSupportedData: "/lookup/info/supported-data",
  lookupInfoAvailability: "/lookup/info/availability",
  lookupValidationSingle: "/lookup/validation/single",
  lookupValidationUpload: "/lookup/validation/upload",
  lookupValidationHistoryBatch: "/lookup/history/validation/batch",
  lookupValidationHistoryBatchDetails: (batchId: string) =>
    `/lookup/history/validation/batch/${batchId}`,
  lookupValidationHistorySingle: "/lookup/history/validation/single",
  lookupTinSearchSingle: "/lookup/tin-search/single",
  lookupTinSearchUpload: "/lookup/tin-search/upload",
  lookupTinSearchHistoryBatch: "/lookup/history/tin-search/batch",
  lookupTinSearchHistorySingle: "/lookup/history/tin-search/single",
} as const;

const returns = {
  returns: "/returns",
  returnsCurrentReturns: "/returns/current-returns",
  returnsFiledReturns: "/returns/filed-returns",
  returnsDataGatheringSummary: "/returns/data-gathering",
  returnsWorkflowTemplates: "/returns/workflow-templates",
  returnsDataGatheringTransactions: "/returns/data-gathering/transactions",
  eFilingCallback: "/returns/e-filing-callback",
  returnDataPreview: (returnId: string) => `/returns/${returnId}`,
  returnFormPreview: (returnId: string) => `/returns/${returnId}/form`,
  returnPayment: (returnId: string) => `/returns/${returnId}/payment`,
  returnTransactions: (returnId: string) => `/returns/${returnId}/transactions`,
  returnsCsvDetails: (csvId: string) => `/returns/data-gathering/${csvId}`,
} as const;

const forgottenPassword = {
  forgottenPassword: "/forgottenPassword",
  forgottenPasswordSuccess: "/forgottenPassword/success",
} as const;

const auth = {
  login: "/api/auth/login",
  logout: "/api/auth/logout",
} as const;

export const routes = {
  dashboard: "/",
  expired: "/expired",
  contactUs: "mailto:support@fonoa.com",
  ...forgottenPassword,
  ...settings,
  ...tax,
  ...reporting,
  ...invoicing,
  ...eInvoicing,
  ...lookup,
  ...returns,
  ...auth,
} as const;

export type RouteKeyType = keyof typeof routes;
export type RouteValueType = typeof routes[RouteKeyType];
